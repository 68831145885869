import './stylesheets/settings/colors.css';
import './stylesheets/settings/animations.css';
import './stylesheets/settings/dimensions.css';
import './stylesheets/settings/fonts.css';
import './stylesheets/settings/layers.css';

import 'reset-css/reset.css';
import './stylesheets/index.css';
import {
  createApp,
  ref,
} from 'vue';
import { createHead } from '@unhead/vue';
import urql from '@urql/vue';
import * as Sentry from '@sentry/vue';
import createRouter from './router.js';
import createClient from './graphql.js';
import createI18N from './i18n.js';
import App from './Application.vue';
import { createSession } from './session/session.js';

export default function createApplication(router, i18n) {
  const app = createApp(App);
  const head = createHead();
  const permissionError = ref(false);
  const { client, websocketClient } = createClient(true, permissionError);
  const gqlClient = ref(client);
  const gqlWebsocketClient = ref(websocketClient);
  router.afterEach(() => {
    // reset the permission error after each navigation, so we can see something.
    permissionError.value = false;
  });
  app.use(router);
  app.use(head);
  app.use(urql, gqlClient);
  app.provide('websocketClient', gqlWebsocketClient);
  app.use(i18n);
  app.directive('test-id', (el, binding) => {
    if (import.meta.env.MODE !== 'production') {
      // eslint-disable-next-line no-param-reassign
      el.dataset.testId = binding.value;
    }
  });
  const session = createSession(gqlClient, permissionError, () => {
    const { client: newClient, websocketClient: newWSClient } = createClient(true, permissionError);
    gqlClient.value = newClient;
    if (gqlWebsocketClient.value) {
      gqlWebsocketClient.value.dispose();
    }
    gqlWebsocketClient.value = newWSClient;
  });
  app.use(session);
  return app;
}

const { lang } = document.documentElement;
const i18n = createI18N(lang || 'nl');
const router = createRouter(i18n.global);

const app = createApplication(router, i18n);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.RELEASE,
    environment: import.meta.env.MODE,
    integrations: [],
    beforeSend(event, hint) {
      if (import.meta.env.DEV) {
        console.error(event, hint);
        return null;
      }
      // Drop the error if we had a 409 in our history, since we did not receive data in that
      // case.
      if (event?.breadcrumbs?.some(
        (bc) => bc.category === 'fetch' && bc.data?.status_code === 409,
      )) {
        return null;
      }
      return event;
    },
    autoSessionTracking: false,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Not relevant NetworkErrors
      'Failed to fetch',
      'Load failed',
      'Importing a module script failed',
      'NetworkError when attempting to fetch resource',
      'Unable to preload CSS',
      'code 409',
      'AbortError',
      'cancelled',
      'geannuleerd',
      'ResizeObserver loop',
      'interne fout',
      'webkit-masked-url:',
      'FormMetadata.js',
      '_logicalFormForControl',
    ],
    denyUrls: [
      /webkit-masked-url:\/\//i,
    ],
  });
}

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  app.mount('#application');
});
