// router.js
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from 'vue-router';

import testRoutes from './test-routes.js';

//
// Utility views
//

const NotFoundView = () => import('./views/static/NotFoundView.vue');

//
// Main views
//
const MainContainer = () => import('./views/main/MainContainer.vue');
const HomeView = () => import('./views/home/HomeView.vue');
const PreregistrationInformationView = () => import('./views/static/PreregistrationInformationView.vue');
const ContactView = () => import('./views/static/ContactView.vue');
const ColofonView = () => import('./views/static/ColofonView.vue');
const CalendarView = () => import('./views/calendar/CalendarView.vue');
const WeekCalendarView = () => import('./views/calendar/WeekCalendarView.vue');
const MonthCalendarView = () => import('./views/calendar/MonthCalendarView.vue');
const ListCalendarView = () => import('./views/calendar/ListCalendarView.vue');
const Login = () => import('./views/session/LoginView.vue');
const Logout = () => import('./views/session/LogoutView.vue');
const RegisterView = () => import('./views/user/account/RegisterView.vue');
const RequestPasswordResetView = () => import('./views/session/RequestPasswordResetView.vue');
const ResetPasswordView = () => import('./views/session/ResetPasswordView.vue');
const BasketView = () => import('./views/user/account/BasketView.vue');
const CheckoutReturnView = () => import('./views/user/account/CheckoutReturnView.vue');
const VerifyEmailView = () => import('./views/user/account/VerifyEmailView.vue');

const AccountView = () => import('./views/user/account/AccountView.vue');
const AccountPersonPanel = () => import('./views/user/account/AccountPersonPanel.vue');
const AccountDogPanel = () => import('./views/user/account/AccountDogPanel.vue');
const AccountOrdersPanel = () => import('./views/user/account/AccountOrdersPanel.vue');
const AccountPreregistrationsPanel = () => import('./views/user/account/AccountPreregistrationsPanel.vue');

const CombinationView = () => import('./views/licenses/combinations/CombinationView.vue');
const CombinationLicensesView = () => import('./views/licenses/combinations/CombinationLicensesView.vue');
const CombinationResultsView = () => import('./views/licenses/combinations/CombinationResultsView.vue');
const CombinationRankingsView = () => import('./views/licenses/combinations/CombinationRankingsView.vue');
const CalendarEventView = () => import('./views/events/CalendarEventView.vue');
const EventManageView = () => import('./views/eventAdmin/EventManageView.vue');
const EventManageGeneralView = () => import('./views/eventAdmin/EventManageGeneralView.vue');
const EventManageShowView = () => import('./views/eventAdmin/show/EventManageShowView.vue');
const EventManageShowsView = () => import('./views/eventAdmin/EventManageShowsView.vue');
const EventManageRegistrationView = () => import('./views/eventAdmin/EventManageRegistrationView.vue');
const EventManageSchemaView = () => import('./views/eventAdmin/EventManageSchemaView.vue');
const EventManageSchemaDayView = () => import('./views/eventAdmin/eventDay/EventManageSchemaDayView.vue');
const EventManageMeasurementsView = () => import('./views/eventAdmin/EventManageMeasurementsView.vue');
const EventManageResultsView = () => import('./views/eventAdmin/EventManageResultsView.vue');
const EventManageResultsDayView = () => import('./views/eventAdmin/eventDay/EventManageResultsDayView.vue');
const EventManageResultsCourseView = () => import('./views/eventAdmin/EventManageResultsCourseView.vue');
const EventManageExportView = () => import('./views/eventAdmin/EventManageExportView.vue');
const GroupGradeView = () => import('./views/events/GroupGradeView.vue');
const GroupPanel = () => import('./views/events/GroupPanel.vue');
const CourseView = () => import('./views/events/CourseView.vue');

const ClubManageView = () => import('./views/clubs/manage/ClubManageView.vue');
const ClubManageGeneralView = () => import('./views/clubs/manage/ClubManageGeneralView.vue');
const ClubManageAccessView = () => import('./views/clubs/manage/ClubManageAccessView.vue');
const ClubManageMembersView = () => import('./views/clubs/manage/ClubManageMembersView.vue');
const ClubManageEventsView = () => import('./views/clubs/manage/ClubManageEventsView.vue');
const ClubManageTeamsView = () => import('./views/clubs/manage/ClubManageTeamsView.vue');

const RankingsView = () => import('./views/rankings/RankingsView.vue');
const RankingView = () => import('./views/rankings/RankingView.vue');

const RankingManageView = () => import('./views/rankings/manage/RankingManageView.vue');
const RankingManageTeamsView = () => import('./views/rankings/manage/RankingManageTeamsView.vue');

const AdminPersonView = () => import('./views/admin/AdminPersonView.vue');
const AdminAccountPanel = () => import('./views/admin/AdminAccountPanel.vue');
const AdminPersonDogPanel = () => import('./views/admin/AdminPersonDogPanel.vue');
const AdminPersonCombinationPanel = () => import('./views/admin/AdminPersonCombinationPanel.vue');

const AdminFinancialSportOverview = () => import('./views/staff/AdminFinancialSportOverview.vue');

const LiveContainer = () => import('./views/live/LiveContainer.vue');
const LiveIndexView = () => import('./views/live/LiveIndexView.vue');
const LiveRingPanel = () => import('./views/live/LiveRingPanel.vue');
const LiveClockPanel = () => import('./views/live/LiveClockPanel.vue');
const LiveRunningOrderPanel = () => import('./views/live/LiveRunningOrderPanel.vue');

const mode = import.meta.env.MODE;
const release = import.meta.env.RELEASE;

function getRoutesFor(i18n, locale, prefix) {
  const localizedPath = (path) => {
    const translatedPath = i18n.t(`route.${path}`, null, { locale });
    if (prefix && path.indexOf('.') === -1) {
      return `${prefix}/${translatedPath}`;
    }
    return translatedPath;
  };

  return [
    {
      path: prefix,
      name: `${locale}:home`,
      component: HomeView,
    },
    {
      path: localizedPath('contact'),
      name: `${locale}:contact`,
      component: ContactView,
    },
    {
      path: localizedPath('preregistrationInformation'),
      name: `${locale}:preregistration-information`,
      component: PreregistrationInformationView,
    },
    {
      path: localizedPath('colofon'),
      name: `${locale}:colofon`,
      component: ColofonView,
    },
    {
      path: localizedPath('calendar'),
      name: `${locale}:calendar`,
      component: CalendarView,
      children: [
        {
          path: localizedPath('calendarType.week'),
          name: `${locale}:calendar:week`,
          component: WeekCalendarView,
        },
        {
          path: localizedPath('calendarType.month'),
          name: `${locale}:calendar:month`,
          component: MonthCalendarView,
        },
        {
          path: localizedPath('calendarType.list'),
          name: `${locale}:calendar:list`,
          component: ListCalendarView,
        },

      ],
    },
    {
      path: localizedPath('clubManage'),
      name: `${locale}:club:manage`,
      component: ClubManageView,
      children: [
        {
          path: localizedPath('clubManageTabs.general'),
          name: `${locale}:club:manage:general`,
          component: ClubManageGeneralView,
        },
        {
          path: localizedPath('clubManageTabs.access'),
          name: `${locale}:club:manage:access`,
          component: ClubManageAccessView,
        },
        {
          path: localizedPath('clubManageTabs.members'),
          name: `${locale}:club:manage:members`,
          component: ClubManageMembersView,
        },
        {
          path: localizedPath('clubManageTabs.events'),
          name: `${locale}:club:manage:events`,
          component: ClubManageEventsView,
        },
        {
          path: localizedPath('clubManageTabs.teams'),
          name: `${locale}:club:manage:teams`,
          component: ClubManageTeamsView,
        },
      ],
    },
    {
      path: localizedPath('combination'),
      name: `${locale}:combination`,
      component: CombinationView,
      children: [
        {
          path: localizedPath('combinationTabs.licenses'),
          name: `${locale}:combination:licenses`,
          component: CombinationLicensesView,
        },
        {
          path: localizedPath('combinationTabs.results'),
          name: `${locale}:combination:results`,
          component: CombinationResultsView,
        },
        {
          path: localizedPath('combinationTabs.rankings'),
          name: `${locale}:combination:rankings`,
          component: CombinationRankingsView,
        },
      ],
    },
    {
      path: localizedPath('event'),
      name: `${locale}:event`,
      component: CalendarEventView,
    },
    {
      path: localizedPath('eventManage'),
      name: `${locale}:event:manage`,
      component: EventManageView,
      children: [
        {
          path: localizedPath('eventManageTabs.general'),
          name: `${locale}:event:manage:general`,
          component: EventManageGeneralView,
        },
        {
          path: localizedPath('eventManageTabs.shows'),
          name: `${locale}:event:manage:shows`,
          component: EventManageShowsView,
          children: [
            {
              path: localizedPath('eventManageTabs.show'),
              name: `${locale}:event:manage:show`,
              component: EventManageShowView,
            },
          ],
        },
        {
          path: localizedPath('eventManageTabs.registration'),
          name: `${locale}:event:manage:registration`,
          component: EventManageRegistrationView,
        },
        {
          path: localizedPath('eventManageTabs.schema'),
          name: `${locale}:event:manage:schema`,
          component: EventManageSchemaView,
          children: [
            {
              path: localizedPath('eventManageTabs.schemaEventDay'),
              name: `${locale}:event:manage:schemaEventDay`,
              component: EventManageSchemaDayView,
            },
          ],
        },
        {
          path: localizedPath('eventManageTabs.measurements'),
          name: `${locale}:event:manage:measurements`,
          component: EventManageMeasurementsView,
        },
        {
          path: localizedPath('eventManageTabs.export'),
          name: `${locale}:event:manage:export`,
          component: EventManageExportView,
        },
        {
          path: localizedPath('eventManageTabs.results'),
          name: `${locale}:event:manage:results`,
          component: EventManageResultsView,
          children: [
            {
              path: localizedPath('eventManageTabs.resultsEventDay'),
              name: `${locale}:event:manage:resultsEventDay`,
              component: EventManageResultsDayView,
              children: [
                {
                  path: localizedPath('eventManageTabs.resultsCourse'),
                  name: `${locale}:event:manage:resultsCourse`,
                  component: EventManageResultsCourseView,
                  meta: { autoHide: true },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: localizedPath('groupGrade'),
      name: `${locale}:groupGrade`,
      component: GroupGradeView,
      children: [
        {
          path: localizedPath('groupGradeTabs.group'),
          name: `${locale}:groupGrade:group`,
          component: GroupPanel,
        },
      ],
    },
    {
      path: localizedPath('course'),
      name: `${locale}:course`,
      component: CourseView,
    },
    {
      path: localizedPath('rankings'),
      name: `${locale}:rankings`,
      component: RankingsView,
    },
    {
      path: localizedPath('ranking'),
      name: `${locale}:ranking`,
      component: RankingView,
    },
    {
      path: localizedPath('rankingManage'),
      name: `${locale}:ranking:manage`,
      component: RankingManageView,
      children: [
        {
          path: localizedPath('rankingManageTabs.teams'),
          name: `${locale}:ranking:manage:teams`,
          component: RankingManageTeamsView,
        },
      ],
    },
    {
      path: localizedPath('login'),
      name: `${locale}:login`,
      component: Login,
    },
    {
      path: localizedPath('logout'),
      name: `${locale}:logout`,
      component: Logout,
    },
    {
      path: localizedPath('register'),
      name: `${locale}:register`,
      component: RegisterView,
    },
    {
      path: localizedPath('resetPassword'),
      name: `${locale}:resetPassword`,
      component: ResetPasswordView,
    },
    {
      path: localizedPath('requestPasswordReset'),
      name: `${locale}:requestPasswordReset`,
      component: RequestPasswordResetView,
    },
    {
      path: localizedPath('verifyEmail'),
      name: `${locale}:verify-email`,
      component: VerifyEmailView,
    },
    {
      path: localizedPath('account'),
      name: `${locale}:account`,
      component: AccountView,
      children: [
        {
          path: localizedPath('accountTabs.person'),
          name: `${locale}:account:person`,
          component: AccountPersonPanel,
        },
        {
          path: localizedPath('accountTabs.dog'),
          name: `${locale}:account:dog`,
          component: AccountDogPanel,
        },
        {
          path: localizedPath('accountTabs.orders'),
          name: `${locale}:account:orders`,
          component: AccountOrdersPanel,
        },
        {
          path: localizedPath('accountTabs.preregistrations'),
          name: `${locale}:account:preregistrations`,
          component: AccountPreregistrationsPanel,
        },
      ],
    },
    {
      path: localizedPath('basket'),
      name: `${locale}:basket`,
      component: BasketView,
    },
    {
      path: localizedPath('checkoutReturn'),
      name: `${locale}:checkout-return`,
      component: CheckoutReturnView,
    },

    {
      path: localizedPath('adminPerson'),
      name: `${locale}:admin:person`,
      component: AdminPersonView,
      children: [
        {
          path: localizedPath('adminPersonTabs.orders'),
          name: `${locale}:admin:person:orders`,
          component: AccountOrdersPanel,
        },
        {
          path: localizedPath('adminPersonTabs.account'),
          name: `${locale}:admin:person:account`,
          component: AdminAccountPanel,
        },
        {
          path: localizedPath('adminPersonTabs.dog'),
          name: `${locale}:admin:person:dog`,
          component: AdminPersonDogPanel,
        },
        {
          path: localizedPath('adminPersonTabs.combination'),
          name: `${locale}:admin:person:combination`,
          component: AdminPersonCombinationPanel,
        },
      ],
    },

    {
      path: localizedPath('adminFinancialSport'),
      name: `${locale}:admin:financial-sport`,
      component: AdminFinancialSportOverview,
    },

    // Catch all for 404 page, localised.
    {
      path: (prefix) ? `${prefix}/:catchAll(.*)` : ':catchAll(.*)',
      name: `${locale}:notFound`,
      component: NotFoundView,
    },
  ];
}

function getLiveRoutesFor(i18n, locale, prefix) {
  const localizedPath = (path) => {
    const translatedPath = i18n.t(`route.${path}`, null, { locale });
    if (prefix && path.indexOf('.') === -1) {
      return `${prefix}/${translatedPath}`;
    }
    return translatedPath;
  };
  return [
    {
      path: '',
      name: `${locale}:live`,
      component: LiveIndexView,
    },
    {
      path: localizedPath('liveRing'),
      name: `${locale}:live:ring`,
      component: LiveRingPanel,
    },
    {
      path: localizedPath('liveClock'),
      name: `${locale}:live:clock`,
      component: LiveClockPanel,
    },
    {
      path: localizedPath('liveRunningOrder'),
      name: `${locale}:live:runningOrder`,
      component: LiveRunningOrderPanel,
    },

    // Catch all for 404 page, localised.
    {
      path: (prefix) ? `${prefix}/:catchAll(.*)` : ':catchAll(.*)',
      name: `${locale}:notFound`,
      component: NotFoundView,
    },
  ];
}

export function createAppRouter(i18n) {

  const routes = [{
    path: '/live',
    component: LiveContainer,
    children: [
      ...getLiveRoutesFor(i18n, 'nl', ''),
      ...getLiveRoutesFor(i18n, 'en', 'en'),
    ],
  },
  {
    path: '/',
    component: MainContainer,
    props: (route) => ({
      transparentHeader: ['login', 'logout', 'home', 'live', 'register', 'requestPasswordReset', 'resetPassword', 'verify-email'].indexOf(route.name?.split(':')[1]) >= 0,
      horizontalPage: ['home'].indexOf(route.name?.split(':')[1]) >= 0,
    }),
    children: [
      ...getRoutesFor(i18n, 'nl', ''),
      ...getRoutesFor(i18n, 'en', 'en'),
    ],
  }];

  routes.push(...testRoutes);

  const router = createRouter({
    history: (import.meta.env.SSR) ? createMemoryHistory() : createWebHistory(),
    routes,
    ...((import.meta.env.SSR) ? {} : {
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(savedPosition);
            });
          });
        }
        if (to.name === from.name) {
          return null;
        }
        return { left: 0, top: 0 };
      },
    }),
  });
  router.beforeEach(async (to, from, next) => {
    if (to.matched.length > 0) {
      const toLocale = to.name && to.name.split(':')[0];

      if (to.name?.indexOf(':') !== -1 && i18n && i18n.locale.value !== toLocale) {

        // eslint-disable-next-line no-param-reassign
        i18n.locale.value = toLocale;

        if (!import.meta.env.SSR) {
          document.documentElement.lang = toLocale;
        }
      }

    }
    return next();
  });
  return router;
}

export default createAppRouter;
