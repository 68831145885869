import { kebabize } from './utils/string.js';

const TestsView = () => import('./tests/TestView.vue');
// noinspection JSUnusedGlobalSymbols
const TestViews = {
  TestAccordionSectionPanel: () => import('./tests/TestAccordionSectionPanel.vue'),
  TestCalendarSelect: () => import('./tests/TestCalendarSelect.vue'),
  TestCard: () => import('./tests/TestCard.vue'),
  TestColours: () => import('./tests/TestColours.vue'),
  TestCombinationRegistrationCard: () => import('./tests/TestCombinationRegistrationCard.vue'),
  TestCombinationsSearchInput: () => import('./tests/TestCombinationsSearchInput.vue'),
  TestCrud: () => import('./tests/TestCRUD.vue'),
  TestDurationBlock: () => import('./tests/TestDurationBlock.vue'),
  TestEventBlocks: () => import('./tests/TestEventBlocks.vue'),
  TestFlowTests: () => import('./tests/TestFlowPanel.vue'),
  TestFormWidgets: () => import('./tests/TestFormWidgets.vue'),
  TestIconLabels: () => import('./tests/TestIconLabels.vue'),
  TestLoaderPane: () => import('./tests/TestLoaderPane.vue'),
  TestPagerPane: () => import('./tests/TestPagerPane.vue'),
  TestPreregistrationInformation: () => import('./tests/TestPreregistrationInformation.vue'),
  TestPrinter: () => import('./tests/TestPrinter.vue'),
  TestPanel: () => import('./tests/TestPanel.vue'),
  TestRealtime: () => import('./tests/TestRealtime.vue'),
  TestRefs: () => import('./tests/TestRefs.vue'),
  TestRichTextInput: () => import('./tests/TestRichTextInput.vue'),
  TestScrollablePane: () => import('./tests/TestScrollablePane.vue'),
  TestSearchInputs: () => import('./tests/TestSearchInputs.vue'),
  TestSortableContainer: () => import('./tests/TestSortableContainer.vue'),
  TestTabbablePanel: () => import('./tests/TestTabbablePanel.vue'),
  TestValidatorForm: () => import('./tests/TestValidatorForm.vue'),
};

const testRoutes = (import.meta.env.DEV || import.meta.env.MODE === 'pytest') ? [
  {
    path: '/test',
    component: TestsView,
    name: 'tests-overview',
    children: Object.entries(TestViews).map(([name, component]) => ({
      name: kebabize(name),
      path: kebabize(name),
      component,
    })).toSorted((a, b) => a.name.localeCompare(b.name)),
  },
] : [];
export default testRoutes;
