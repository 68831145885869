import { createI18n } from 'vue-i18n';

import en from './lang/en.json';
import nl from './lang/nl.json';

export default function createI18N(lang) {
  const i18n = createI18n({
    allowComposition: true,
    legacy: false,
    locale: lang,
    fallbackLocale: 'nl',
  });
  i18n.global.setLocaleMessage('nl', nl);
  i18n.global.setDateTimeFormat('nl', nl.dateFormats);
  i18n.global.setNumberFormat('nl', nl.numberFormats);
  i18n.global.setLocaleMessage('en', en);
  i18n.global.setDateTimeFormat('en', en.dateFormats);
  i18n.global.setNumberFormat('en', en.numberFormats);
  return i18n;
}
